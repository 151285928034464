import { useState } from 'react';
import './unauthorized.scss';

import { BrowserRouter, Link, Routes ,Route } from 'react-router-dom';
import { Button, Container } from '@gitlab-rtsensing/component-library';
import UnauthorizedPageContent from './unauthorized-page-content';
import CautionIcon from '../assets/icons/caution-image';

type AdminMessage = {
  action: 'access request successful';
};

const UnauthorizedPage = (props: any) => {
  
  // function handleRequestAccessClick() {
  //   setRequestAccessModalOpen(true);

  //   const abortController = new AbortController();
  //   window.addEventListener(
  //     'message',
  //     (event: MessageEvent<AdminMessage>) => {
  //       if (
  //         event.origin !== window.location.origin ||
  //         event.data.action !== 'access request successful'
  //       ) {
  //         return;
  //       }
  //       abortController.abort();
  //       setAccessRequestSuccessful(true);
  //     },
  //     { signal: abortController.signal },
  //   );
  // }

  return (
    <BrowserRouter>
      <Container>
      <Routes>
                <Route path="/unauthorized" element={ <UnauthorizedPageContent/>} />
              <Route path="*" element={<div className='dct-invalid-site'>
                <CautionIcon fill='#2f2f2f'/>
                This site is not configured in DCT</div>}/>
                
            </Routes>
      
      </Container>
    </BrowserRouter>
  );
};

export default UnauthorizedPage;
