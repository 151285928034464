import { lazy, Suspense, useEffect } from 'react';
// import { Loader, Modal } from '@gitlab-rtsensing/component-library';
import { lazyImport } from '../../utils/lazy-import';
import './index.scss';
import AmgenNetworks from '../../assets/icons/amgen-networks';
import Logo from '../../assets/images/Amgen-Sensing.png';
import { BrowserRouter, useNavigate } from 'react-router-dom';

interface RequestAccessModalProps {
  title?: string;
  opened: boolean;
  onRequestAccessModalClosed: () => void;
  accessRequestSuccessful: boolean;
}

const RequestAccessForm = lazy(() =>
  lazyImport('@amgen/rtsensing-request-form'),
);

const RequestAccessModal = ({
  title = 'Request Access',
  opened,
  onRequestAccessModalClosed,
  accessRequestSuccessful,
}: RequestAccessModalProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    const el = document.querySelector(
      '.unauth-request-access-modal .ops-modal-card--opened',
    );
    el?.classList.remove('px-6');
    el?.classList.remove('py-4');
  });

  const handleModalClose = () => {
    onRequestAccessModalClosed();
    if (accessRequestSuccessful) {
      navigate('/');
    }
  };

  return (
    <div>In the request Access Modal App</div>
    // <Modal
    //   className="unauth-request-access-modal"
    //   opened={opened}
    //   onClose={handleModalClose}
    // >
    //   <Modal.Header
    //     contentClassName="d-flex align-items-center"
    //     className="request-modal-header"
    //   >
    //     <AmgenNetworks fill="var(--ops-white)" />
    //     <img src={Logo} alt="amgen logo"></img>
    //     <Modal.Title className="request-modal-title" title={title} />
    //   </Modal.Header>
    //   <Suspense fallback={<Loader />}>
    //     <RequestAccessForm />
    //   </Suspense>
    // </Modal>
  );
};

export default RequestAccessModal;
